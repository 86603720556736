





























import Vue from 'vue';
import noop from 'lodash/noop';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

@Component({
  name: 'AppPaymentConfirmDialog',
  popupOptions: {
    size: 'sm',
  },
  computed: {
    ...mapState('auth', [ 'user', 'balance' ]),
  },
  props: {
    orderAmount: {
      type: [ Number, String ],
      required: true,
    },
    onConfirm: {
      type: Function,
      required: true,
      default: noop,
    },
    onClose: {
      type: Function,
      default: noop,
    },
  },
})
export default class PaymentConfirmDialog extends Vue {
  // props
  onConfirm!: () => void;
  onClose: () => void;
  orderAmount: number;
  balance: number;

  // data
  loading: boolean = false;
  // methods
  closeDialog() {
    this.$dialog.close();
    this.onClose();
  }

  confirmPayment() {
    this.loading = true;
    this.$dialog.close();
    this.onConfirm();
  }
}
