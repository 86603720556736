var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("div", { staticClass: "font-weight-500 text-center mt-3" }, [
        _vm._v(
          "\n    Подтвердите списание " +
            _vm._s(_vm.orderAmount) +
            " руб. за заказ\n  "
        )
      ]),
      _c("div", { staticClass: "mt-3 text-center" }, [
        _vm._v("\n    Текущий баланс " + _vm._s(_vm.balance) + " руб.\n  ")
      ]),
      _c(
        "div",
        { staticClass: "mt-3 text-center" },
        [
          _c(
            "app-button",
            {
              attrs: {
                variant: "primary-light",
                size: "sm",
                disabled: _vm.loading
              },
              on: {
                "~click": function($event) {
                  return _vm.confirmPayment($event)
                }
              }
            },
            [_vm._v("\n      Подтвердить\n    ")]
          ),
          _c(
            "app-button",
            {
              attrs: { variant: "light", size: "sm" },
              on: { click: _vm.closeDialog }
            },
            [_vm._v("\n      Отмена\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }