var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-8 offset-2 page-wrapper p-5" },
      [
        _vm._l(_vm.items, function(item, index) {
          return _c("div", { key: index, staticClass: "wholesale-item" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "app-input",
                  {
                    attrs: { placeholder: "Адрес или кадастровый номер" },
                    on: { paste: _vm.onPaste },
                    model: {
                      value: item.object_key,
                      callback: function($$v) {
                        _vm.$set(item, "object_key", $$v)
                      },
                      expression: "item.object_key"
                    }
                  },
                  [
                    _vm.items.length > 1
                      ? _c("i", {
                          staticClass: "far fa-trash-alt",
                          on: {
                            click: function($event) {
                              return _vm.removeItem(index)
                            }
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _c("div", { staticClass: "row mt-1" }, [
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "app-checkbox",
                        {
                          model: {
                            value: item.egrn,
                            callback: function($$v) {
                              _vm.$set(item, "egrn", $$v)
                            },
                            expression: "item.egrn"
                          }
                        },
                        [_vm._v("\n              Отчет ЕГРН\n            ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "app-checkbox",
                        {
                          model: {
                            value: item.rightList,
                            callback: function($$v) {
                              _vm.$set(item, "rightList", $$v)
                            },
                            expression: "item.rightList"
                          }
                        },
                        [_vm._v("\n              Переход прав\n            ")]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ])
        }),
        _c(
          "div",
          { staticClass: "d-flex justify-content-between" },
          [
            _c(
              "app-button",
              {
                attrs: { variant: "primary-light", size: "sm" },
                on: {
                  click: function($event) {
                    return _vm.addItem()
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-plus-circle fa-xs" }),
                _vm._v(" Добавить ещё адрес\n      ")
              ]
            ),
            _c(
              "app-button",
              {
                attrs: { size: "sm", loading: _vm.loading },
                on: { click: _vm.onSubmit }
              },
              [
                _vm._v(
                  "\n        Оформить заказ " +
                    _vm._s(_vm._f("currency")(_vm.totalPrice)) +
                    "\n      "
                )
              ]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }