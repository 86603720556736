import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { find, isEmpty, first, last, isNil } from 'lodash';
import { _OrderTypes } from 'shared/store/modules/order/types';
import AppPaymentSuccess from '@/components/layout/Dialogs/PaymentSuccess.vue';
import AppPaymentConfirmDialog from 'shared/components/Product/PaymentConfirmDialog.vue';
import { AuthTypes } from 'shared/store/modules/auth/types';

interface WholesaleItem {
  object_key: string;
  egrn: boolean;
  rightList: boolean;
  egrn_coupon_id: string;
  rightList_coupon_id: string;
}

@Component({
  computed: {
    ...mapGetters(_OrderTypes.namespace, [
      _OrderTypes.getters.TOTAL_AMOUNT,
    ]),
    ...mapState([ 'products' ]),
    ...mapState('auth', [ 'balance' ]),
  },
})
export default class WholesaleOrder extends Vue {
  // props
  products: Product[];
  balance: number;
  totalAmount: number;

  // data
  items: WholesaleItem[] = [];
  loading: boolean = false;

  get product(): { egrn: Product; rightList: Product } {
    const egrn = find(this.products, { product_name: 'EgrnObject' });
    const rightList = find(this.products, { product_name: 'EgrnRightList' });

    return { egrn, rightList };
  }

  get usedCoupons(): { egrn: number; rightList: number } {
    let egrn = 0;
    let rightList = 0;

    this.items.forEach(item => {
      if (item.egrn_coupon_id) {
        egrn += 1;
      }

      if (item.rightList_coupon_id) {
        rightList += 1;
      }
    });

    return {
      egrn,
      rightList,
    };
  }

  get totalPrice(): number {
    let price = 0;

    this.items.forEach(item => {
      if (item.egrn) {
        price += parseInt(this.product.egrn.product_price, 10);

        if (!isEmpty(item.egrn_coupon_id)) {
          const coupon = first(this.product.egrn.coupons);
          price -= parseInt(coupon.discount, 10);
        }
      }

      if (item.rightList) {
        price += parseInt(this.product.rightList.product_price, 10);

        if (!isEmpty(item.rightList_coupon_id)) {
          const coupon = first(this.product.rightList.coupons);
          price -= parseInt(coupon.discount, 10);
        }
      }
    });

    return price;
  }

  created() {
    this.addItem();
  }

  addItem(object_key = '') {
    const lastItem = last(this.items);
    const egrn = isNil(lastItem) ? true : lastItem.egrn;
    const rightList = isNil(lastItem) ? false : lastItem.rightList;

    let egrnCouponId: string = null;
    let rightListCouponId: string = null;

    if (!isEmpty(this.product.egrn.coupons)) {
      const coupon = first(this.product.egrn.coupons);
      if (coupon.count - this.usedCoupons.egrn > 0) {
        egrnCouponId = coupon.coupon_id;
      }
    }

    if (!isEmpty(this.product.rightList.coupons)) {
      const coupon = first(this.product.rightList.coupons);
      if (coupon.count - this.usedCoupons.rightList > 0) {
        rightListCouponId = coupon.coupon_id;
      }
    }

    this.items.push({
      object_key,
      egrn,
      rightList,
      egrn_coupon_id: egrnCouponId,
      rightList_coupon_id: rightListCouponId,
    });
  }

  removeItem(index: number) {
    this.items.splice(index, 1);
  }

  async onSubmit() {
    if (this.balance >= this.totalAmount && this.totalAmount !== 0) {
      this.$dialog.open({
        component: AppPaymentConfirmDialog,
        props: {
          orderAmount: this.totalAmount,
          onConfirm: this.continueOrder,
        },
      });
    } else {
      this.continueOrder();
    }
  }

  async continueOrder() {
    const orderItems: OrderItem[] = [];

    const egrn = find(this.products, { product_name: 'EgrnObject' });
    const rightList = find(this.products, { product_name: 'EgrnRightList' });

    this.items.forEach(item => {
      if (isEmpty(item.object_key)) {
        return;
      }

      if (item.egrn) {
        const orderItem: OrderItem = {
          object_key: item.object_key,
          product_id: egrn.product_id,
        };

        if (item.egrn_coupon_id) {
          orderItem.coupon_id = item.egrn_coupon_id;
        }

        orderItems.push(orderItem);
      }

      if (item.rightList) {
        const orderItem: OrderItem = {
          object_key: item.object_key,
          product_id: rightList.product_id,
        };

        if (item.rightList_coupon_id) {
          orderItem.coupon_id = item.rightList_coupon_id;
        }

        orderItems.push(orderItem);
      }
    });

    this.loading = true;

    if (isEmpty(orderItems)) {
      return;
    }

    try {
      const data = await this.$api.order.Send({
        order_items: orderItems,
        redirect_url: location.href,
        use_account_balance: this.balance >= this.totalPrice,
      });

      if (data.use_account_balance && !data.account_info.not_enough_money) {
        this.$store.commit(
          AuthTypes.mutations.SET_BALANCE,
          parseInt(data.account_info.balance_current)
        );

        this.$dialog.open({
          component: AppPaymentSuccess,
          props: {
            text: `Заказ на сумму ${data.account_info.balance_difference} руб. успешно оформлен <br><br>
            Текущий баланс ${data.account_info.balance_current} руб.`,
          },
        });
      } else {
        location.assign(data.payment_data.confirmation.confirmation_url);
      }
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Ошибка при отправке заказа' });
    }

    this.loading = false;
  }

  onPaste(event: Event) {
    event.preventDefault();

    const data = (event as any).clipboardData;
    const text = data.getData('text');

    const arr: any[] = text.split(/\n/);

    arr.forEach((text: string) => {
      const match = text.match(/([a-zA-Zа-яА-Я].*)/);
      let objectKey = text;

      if (match && match[0]) {
        objectKey = match[0];
      }

      this.addItem(objectKey);
    });

    this.items = this.items.filter(item => item.object_key.length);
  }
}
