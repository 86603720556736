var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("\n    Оптовый заказ\n  ")
      ]),
      _c("app-wholesale-order")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }