









import Vue from 'vue';
import AppWholesaleOrder from 'shared/module/WholesaleOrder/WholesaleOrder.vue';

export default Vue.extend({
  components: { AppWholesaleOrder },
});
